import { useEffect, useCallback, useMemo, useRef } from "react";
import Router from "next/router";
import useSWR from "swr";
import fetchJson, { FetchError } from "./fetchJson";
import _ from "lodash"; // Import lodash for deep comparison

interface Metadata {
  campaigns?: number[];
  agencyId: string;
  campaignView?: string;
  isClient: boolean;
}
interface User {
  id: number;
  email: string;
  roles: string[];
  agencyId?: string;
  avatar?: string;
  campaignView?: string;
  metadata: Metadata;
}

interface Props {
  shouldRedirect?: boolean;
}

export default function useUser(
  { shouldRedirect }: Props = {
    shouldRedirect: true,
  },
) {
  const {
    data: u,
    mutate: mutateUser,
    isLoading,
    isValidating,
    error,
  } = useSWR(`/auth/user`, fetchJson);
  console.log("User here", u);
  const user: User = useMemo(
    () =>
      u
        ? {
            ...(u || {}),
            avatar: u?.metadata?.avatar,
            campaignView: u?.metadata?.campaignView,
            agencyId:u?.metadata?.agencyId
          }
        : u,
    [u],
  );

  // Use useRef to store the previous user value
  const prevUserRef = useRef<User | undefined>();

  // Memoize the user object with deep comparison
  const stableUser = useMemo(() => {
    if (!_.isEqual(user, prevUserRef.current)) {
      prevUserRef.current = user;
    }
    return prevUserRef.current;
  }, [user]);

  const stableShouldRedirect = useMemo(() => shouldRedirect, [shouldRedirect]);

  useEffect(() => {
    if (error && stableShouldRedirect && !isValidating) {
      if (error instanceof FetchError) {
        console.log(error);
        alert(error);
        window.location.href = "/auth";
        return;
      }
    }

    if (error?.message === "access-code") {
      Router.push("/access");
      return;
    }

    if (error?.message === "payment-required") {
      Router.replace("/payment");
      return;
    }
  }, [stableUser, error, isLoading, isValidating, stableShouldRedirect]);

  const checkRoles = useCallback(
    (roles: string[]) => {
      console.log('stableUser',stableUser)
      if (!stableUser) return false;
      return roles.some((role) => stableUser?.roles.includes(role));
    },
    [stableUser],
  );

  return { user: stableUser, mutateUser, isLoading, error, checkRoles };
}
